.home-container {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  header {
    border-bottom: 2px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2.5em;
    color: #2A3F54;
  }
  
  h2 {
    font-size: 2em;
    color: #4A4A4A;
  }
  
  h3 {
    font-size: 1.5em;
    color: #4A4A4A;
  }
  
  .resources h3 {
    margin-top: 30px;
  }
  
  .resources a {
    color: #007bff; /* Bootstrap default blue for links */
    text-decoration: none;
  }
  
  .resources a:hover {
    text-decoration: underline;
  }
  

  p, li {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  ol {
    margin-left: 20px;
  }
  
  button {
    background-color: #5CB85C; /* Bootstrap primary button color */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
  }
  
  button:hover {
    background-color: #4CAE4C; /* Darken color on hover */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  /* For spacing between buttons */
  .button-container {
    margin-top: 20px;
  }
  
  .button-explanation {
    font-size: 0.9em;
    color: #666;
    margin-top: 10px;
  }
  